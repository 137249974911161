import { Container, Grid } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import EventCard from '../components/eventos/EventCard';
import SEO from '../components/layout/seo';
import PageHeader from '../ui/PageHeader';

const Eventos = () => {
  const [, setNextEvent] = useState(null);

  const { allDatoCmsEvent } = useStaticQuery(graphql`
    {
      allDatoCmsEvent(sort: { fields: datetime, order: DESC }) {
        nodes {
          id
          title
          datetime
          slug
          address
          contentNode {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    allDatoCmsEvent.nodes.map((e) => {
      if (new Date(e.datetime) > Date.now()) {
        return setNextEvent(e);
      }

      return null;
    });
  }, [allDatoCmsEvent.nodes]);

  return (
    <>
      <SEO title="Eventos Alphanorth" />
      <Container maxWidth="md">
        <PageHeader title="Últimos Eventos" />

        <Grid container spacing={3}>
          {allDatoCmsEvent.nodes.map((event) => {
            if (new Date(event.datetime) < Date.now()) {
              return (
                <Grid item xs={12} key={event.id}>
                  <EventCard
                    title={event.title}
                    datetime={event.datetime}
                    slug={event.slug}
                    address={event.address}
                    excerpt={event.contentNode.childMarkdownRemark.excerpt}
                  />
                </Grid>
              );
            }

            return null;
          })}
        </Grid>
      </Container>
    </>
  );
};

export default Eventos;
