import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';
import { Button, Link } from 'gatsby-theme-material-ui';

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  background: white;
  border: 0.1rem solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    color: white;
  }
`;

const StyledTitle = styled(Typography)`
  font-family: 'Arboria', sans-serif;

  a {
    transition: 0.2s all ease;

    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }

  &:hover {
    text-decoration: none;
  }
`;

const EventCard = ({ title, datetime, slug, address }) => (
  <Box marginY={1}>
    <Typography
      variant="caption"
      style={{ letterSpacing: '0.1rem', fontWeight: 'bolder' }}
    >
      {new Intl.DateTimeFormat('pt-br').format(new Date(datetime))}
    </Typography>
    <StyledTitle variant="h4" component="h2" paragraph>
      <Link to={`/eventos/${slug}`}>{title}</Link>
    </StyledTitle>

    {new Date(datetime) < Date.now() ? (
      <StyledButton
        to={`/eventos/${slug}`}
        variant="contained"
        color="primary"
        disableElevation
      >
        Veja como foi
      </StyledButton>
    ) : (
      <StyledButton
        to={`/eventos/${slug}`}
        variant="contained"
        color="primary"
        disableElevation
      >
        Saiba mais
      </StyledButton>
    )}
  </Box>
);

EventCard.propTypes = {
  title: PropTypes.string.isRequired,
  datetime: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default EventCard;
